import * as React from "react"
import * as styles from "../css/variables.module.css"
import Head from "./head"
import Footer from "./footer"
import NavBar from  "./navbar"

// Layout component for shadowing
// replace this component with a custom layout component
// in src/gatsby-theme-landing-page/components/layout.js
export default function Layout(props) {
  return (
    <div className={styles.root}>
      <NavBar />
      <Head {...props} />
      {props.children}
      <Footer />
    </div>
  );
}
